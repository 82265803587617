import React, {useEffect, useState} from 'react';
import {PageHeader, Form, Input, Button, Upload} from "antd";
import {Link, useHistory} from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';

/**
 * 묻고 답하기
 * @returns {JSX.Element}
 * @constructor
 */
const QnaWrite = ({articleService, projectInfo}) => {

    let projectNo = projectInfo.projectNo;
    const history = useHistory();

    const [form] = Form.useForm();
    const [files, setFiles]  = useState([]);

    const onFinish = (values) => {
        const formData = new FormData();
        files.forEach((file) =>  formData.append("files", file));
        formData.append("title", values.title);
        formData.append("content", values.content);

        articleService.postQuestion(projectNo,formData).then(res => {
            history.push(`/qnaList`);
        })
    };

    const props = {
        onRemove: file => {
            const index = files.indexOf(file);
            const newFileList = files.slice();
            newFileList.splice(index, 1);
            setFiles(newFileList);
        },
        beforeUpload: (file,fileList) => {

            var maxSize = 10 * 1024 * 1024;
            var fileSize = 0;
            for(let one of fileList){
                var fileSize = fileSize + one;
            }

            if(fileSize > maxSize){
                alert("첨부파일 사이즈는 10MB 이내로 등록 가능합니다.");
                return false;
            }
            setFiles([...files, ...fileList]);
            return false;
        },
        accept : "image/*",
        multiple: true
    };

    const formItemLayout = {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }

    const buttonItemLayout = {
            wrapperCol: {
            span: 14,
            offset: 4,
            },
        };

    return (
        <>
        <Form
        {...formItemLayout}
         name="qnaWrite"
         form={form}
         onFinish={onFinish}
        >
            <Form.Item
                name="title"
                label="제목"
                rules={[
                {
                    required: true,
                    message: '제목을 입력해 주세요.',
                },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="content"
                label="내용"
            >
                <Input.TextArea showCount rows={6} maxLength={1000} />
            </Form.Item>
            <Form.Item
                name="fileUpload"
                label="파일"
            >
                <Upload {...props} fileList={files}>
                    <Button icon={<UploadOutlined />}>업로드</Button>
                </Upload>
            </Form.Item>
            <Form.Item {...buttonItemLayout}>
                <Button type="primary" htmlType="submit">등록</Button>
            </Form.Item>
        </Form>
        </>
    );
};

export default QnaWrite;
