import React, { memo, useEffect, useState } from "react";
import Loading from "../../common/Loading";
import ReactImageAnnotate from "react-image-annotate";
import { Button, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import * as keypointDefinition from "../../../keypointDefinition.js";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

/**
 * 어노테이션 검수 페이지(비디오)
 * @returns {JSX.Element}
 * @constructor
 */
const AnnotationVideoInspection = memo(
  ({ projectInfo, annotationService, workFolderInfo }) => {
    let projectNo = projectInfo.projectNo;
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [labeDesc, setLabeDesc] = useState([]);
    const [classDesc, setClassDesc] = useState({});
    const [imageFileList, setImageFiles] = useState([]);
    const [totalDataList, setTotalData] = useState([]);
    const [totalLength, setTotalLength] = useState(0);
    const [tagList, setTagList] = useState([]);
    const [objectCodeList, setObjectCodeList] = useState([]);
    const [rejectCodeObj, setRejectCodeObj] = useState({});
    const [metaColList, setMetaColList] = useState({});
    const [rejectDesc, setRejectDesc] = useState({});
    const [partIndex, setPartIndex] = useState(0);
    let annotationType = workFolderInfo.dataType;
    let workFolderNo = workFolderInfo.taskFolderNo;
    let jobStatus = workFolderInfo.status;
    const regionCls = ["얼굴", "번호판", "키포인트"];
    const kpdef = keypointDefinition.Definitions;
    const tokenInfo = annotationService.getHeaders();

    //나가기 모달 파트
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    function setClassCodes(classCodes) {
      let tags = new Array();
      let rejects = new Object();
      let codes = new Array();
      let metaz = new Object();
      for (let codeObj of classCodes) {
        switch (codeObj.dataType) {
          case "OBJ":
            codes.push(codeObj.className);
            break;
          case "REJECT":
            rejects[codeObj.classNo] = codeObj.classDescription;
            break;
          case "TAG":
            tags.push(codeObj.className);
            break;
          case "META":
            metaz[codeObj.className] = codeObj.classDescription.split("|");
            break;
          default:
            break;
        }
      }
      setTagList(tags);
      codes = codes.concat(["얼굴", "번호판", "키포인트"]);
      const set = new Set(codes);
      setObjectCodeList([...set]);
      setRejectCodeObj(jobStatus == "3" ? rejects : {});
      setMetaColList(metaz);
    }

    useEffect(() => {
      annotationService
        .getFolderImages(workFolderNo, annotationType)
        .then((res) => {
          console.log(res);
          const images = res.images;
          const classCodes = res.classCodes;
          setClassCodes(classCodes);
          const headApi = process.env.REACT_APP_BASE_URL + "/images/";
          let sourceList = new Array();
          //우측 표시용 변수 초기화
          let descList = new Array(); //검출 목록 텍스트들의 List
          let startIdx = 0; //각 검출 목록의 시작 page
          let labelCnt = 0; //각 검출 목록의 식별 건수
          let labelLen = 0; //각 검출 목록의 등장 길이
          let clsObj = new Object();
          let rejectedObj = new Object(); // 재검수 시 반려한 목록 가이드

          for (let i = 0; i < images.length; i++) {
            //카운트용
            let typeCnt = {};
            let tempCls = new Array();
            let regi = [];
            let keyObj = {};
            let jsonData = images[i].jsonData;
            for (let r of jsonData) {
              regi = r.labelData ? regi.concat(r.labelData) : regi;
              const { ["labelData"]: labeldatas, ...keyData } = r;
              keyObj[keyData.dataType] = keyData.jsonDataNo;
              if (r.labelData.length > 0) {
                typeCnt[r.dataType] = r.labelData.length;
              }
              for (let j = 0; j < regi.length; j++) {
                Object.assign(regi[j], { isInspect: true });
              }
            }

            // let inspectCode = images[i].rejectedCode ? images[i].rejectedCode : 1;
            let inspectCode = images[i].rejectedCode
              ? images[i].rejectedCode
              : 0;
            let inspectReason = images[i].rejectedReason
              ? images[i].rejectedReason
              : "";
            let metaDescription = images[i].metaDescription
              ? images[i].metaDescription
              : {};
            //프리라벨 데이터 거르는 로직 장기적으로 필요.
            let json = {
              key: keyObj,
              src: headApi + images[i].fileNo + "?taskFolderNo=" + workFolderNo,
              name: "  / " + images.length,
              typeCnt: typeCnt,
              regions: regi,
              preLabelData: [],
              metaDescription: metaDescription,
              inspectCode: inspectCode,
              inspectReason: inspectReason,
            };
            sourceList.push(json);

            // if (inspectCode && inspectCode != "1") {
            if (inspectCode && inspectCode != 0) {
              rejectedObj[i + 1] = [inspectCode, inspectReason];
            }

            //라벨 존재하는 리스트 생성용 로직
            if (regi.length != labelCnt || i + 1 == images.length) {
              //라벨 개수에 변화가 생겼을 때
              if (labelCnt == 0) {
                //1.이전이 0개였음
                startIdx = i + 1;
              } else if (regi.length == 0) {
                //2.0개 초과에서 0개로 변화함
                let endIdx = startIdx + labelLen;

                let descText = "";
                /*
              for(let k=0;k<classes.length;k++){
                descText = descText + classes[k] + " ";
              }
              */
                for (let key in sourceList[i - 1].typeCnt) {
                  let typeName = key;
                  if (key == "FACE") {
                    typeName = "얼굴";
                  } else if (key == "LP") {
                    typeName = "번호판";
                  }
                  descText =
                    descText +
                    typeName +
                    " " +
                    sourceList[i - 1].typeCnt[key] +
                    "건 ";
                }
                let ranges =
                  labelLen == 0
                    ? startIdx.toString()
                    : startIdx.toString() + " ~ " + endIdx.toString();
                let text = {
                  name: descText,
                  ranges: ranges,
                  startIdx: startIdx,
                  endIdx: endIdx,
                  cnt: endIdx - startIdx + 1,
                };

                /*
              let classes = sourceList[i-1].classes;
              for(let k=0;k<classes.length;k++){
                let tt = classes[k];
                let temp = clsObj[tt] != undefined ? clsObj[tt] : new Array();
                temp.push(text);
                clsObj[tt] = temp;
              }
              */

                descList.push(text);
              } else {
                //3.0개 초과에서 0개 아닌 다른 개수로 변화함.
                let endIdx = startIdx + labelLen;

                //동영상이 끝임.
                if (i + 1 == images.length) {
                  endIdx = endIdx + 1;
                }

                let descText = "";
                /*
              for(let k=0;k<classes.length;k++){
                descText = descText + classes[k] + " ";
              }
              */
                for (let key in sourceList[i - 1].typeCnt) {
                  let typeName = key;
                  if (key == "FACE") {
                    typeName = "얼굴";
                  } else if (key == "LP") {
                    typeName = "번호판";
                  }
                  descText =
                    descText +
                    typeName +
                    " " +
                    sourceList[i - 1].typeCnt[key] +
                    "건 ";
                }
                let ranges =
                  labelLen == 0
                    ? startIdx.toString()
                    : startIdx.toString() + " ~ " + endIdx.toString();
                let text = {
                  name: descText,
                  ranges: ranges,
                  startIdx: startIdx,
                  endIdx: endIdx,
                  cnt: endIdx - startIdx + 1,
                };

                /*
              let classes = sourceList[i-1].classes;
              for(let k=0;k<classes.length;k++){
                let tt = classes[k];
                let temp = clsObj[tt] != undefined ? clsObj[tt] : new Array();
                temp.push(text);
                clsObj[tt] = temp;
              }
              */

                descList.push(text);
                startIdx = i + 1;
              }
              //변화로 인한 초기화
              labelCnt = regi.length;
              labelLen = 0;
            } else {
              //라벨 개수 유지 시
              labelLen = labelLen + 1;
            }
          }
          //console.log(clsObj);
          //console.log(descList);
          //console.log(sourceList);

          //결과 없으면 일단 내보내자.
          if (sourceList.length == 0) {
            alert("이미지 로드 중 오류가 발생했습니다.");
            history.push("/inspcProgressList");
          }

          setLabeDesc(descList);
          setClassDesc(clsObj);
          setTotalData(sourceList);
          setRejectDesc(rejectedObj);
          setTotalLength(sourceList.length);
          setImageFiles(sourceList.slice(0, 900));
          setLoading(false);
          document.querySelector(".taskDescTab").click();
        })
        .catch((error) => {
          console.error(error);
        });
    }, [annotationService]);

    const AnnotationTool = ReactImageAnnotate;
    return (
      <>
        {isLoading ? (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Skeleton
                variant="text"
                width={300}
                height={50}
                style={{ marginLeft: "20px" }}
              />
              <Skeleton
                variant="rect"
                width={160}
                height={38}
                style={{ marginRight: "140px", marginTop: "5px" }}
              />
              <Skeleton
                variant="rect"
                width={200}
                height={38}
                style={{ marginTop: "5px" }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <Skeleton
                variant="rect"
                width={20}
                height={650}
                style={{ marginLeft: "15px" }}
              />
              <Skeleton
                variant="rect"
                width={1350}
                height={780}
                style={{ marginLeft: "15px" }}
              />
              <Skeleton
                variant="rect"
                width={400}
                height={780}
                style={{ marginLeft: "5px" }}
              />
            </div>
          </div>
        ) : (
          <>
            <AnnotationTool
              labelImages
              regionClsList={regionCls}
              regionTagList={tagList}
              workType={"inspect"}
              enabledTools={["pan", "zoom", "저장", "나가기"]}
              keypointDefinitions={kpdef}
              metaDataColumn={metaColList}
              labelDescription={labeDesc}
              classDescription={classDesc}
              rejectedDescription={rejectDesc}
              rejectCodeObj={rejectCodeObj}
              images={imageFileList}
              totalData={totalDataList}
              totalLength={totalLength}
              partIndex={partIndex}
              jobInfo={workFolderInfo}
              tokenInfo={tokenInfo}
              onExit={(args) => {
                let savedPage = window.localStorage.getItem(workFolderNo)
                  ? parseInt(
                      Number(
                        window.localStorage
                          .getItem(workFolderNo)
                          .replace(/[^0-9\.]+/g, "")
                      )
                    ) + 1
                  : 1;
                let curPage = parseInt(
                  Number(document.getElementById("pagingBox").value)
                );
                let curCheck =
                  JSON.stringify(args.images[args.selectedImage].regions) ==
                  JSON.stringify(totalDataList[curPage - 1].regions);

                if (savedPage == curPage && curCheck) {
                  history.push("/inspcProgressList");
                } else {
                  handleClickOpen();
                }
              }}
              onSave={(args, totalDataArray) => {
                console.log(totalDataArray);

                //분리한 데이터 재통합
                let partIndex = args.partIndex;
                let imagesPrev = args.images;
                totalDataArray[partIndex] = imagesPrev;
                let sendData = new Array();
                totalDataArray.map((val) => {
                  sendData = sendData.concat(val);
                });

                //전송 데이터 선언
                let jsonList = new Array();
                let reqList = new Array();

                if (annotationType == "FL") {
                  let lpJsonList = new Array();
                  for (let i = 0; i < sendData.length; i++) {
                    let labelData = sendData[i].regions;
                    let faceLabelData = [];
                    let lpLabelData = [];
                    labelData.forEach((element, idx) => {
                      element.cls === "번호판"
                        ? lpLabelData.push(element)
                        : faceLabelData.push(element);
                    });
                    var json = {
                      jsonDataNo: sendData[i].key["FACE"],
                      rejectedCode: sendData[i].inspectCode,
                      rejectedReason: sendData[i].inspectReason,
                      status: sendData[i].inspectCode == 1 ? "1" : "3",
                    };
                    jsonList.push(json);
                    var lpJson = {
                      jsonDataNo: sendData[i].key["LP"],
                      rejectedCode: sendData[i].inspectCode,
                      rejectedReason: sendData[i].inspectReason,
                      status: sendData[i].inspectCode == 1 ? "1" : "3",
                    };
                    lpJsonList.push(lpJson);
                  }
                  reqList.push(lpJsonList);
                } else {
                  for (let i = 0; i < sendData.length; i++) {
                    var json = {
                      jsonDataNo: sendData[i].key[annotationType],
                      rejectedCode: sendData[i].inspectCode,
                      rejectedReason: sendData[i].inspectReason,
                      status: sendData[i].inspectCode == 1 ? "1" : "3",
                    };
                    jsonList.push(json);
                  }
                }

                reqList.push(jsonList);
                console.log(reqList);
                annotationService
                  .postInspectJson(reqList, workFolderNo)
                  .then(function(res) {
                    notification["success"]({
                      message: "검수 정보 저장 완료",
                    });
                  })
                  .catch((error) => {
                    console.error(error);
                    notification["error"]({
                      message: "저장에 실패하였습니다.",
                    });
                  });
              }}
            />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`알림`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  변경사항이 저장되지 않을 수 있습니다. 작업 페이지를
                  나가시겠습니까?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  취소
                </Button>
                <Button
                  onClick={() => history.push("/inspcProgressList")}
                  color="primary"
                  autoFocus
                >
                  나가기
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    );
  }
);

export default AnnotationVideoInspection;
