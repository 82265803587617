import React, {useEffect, useState} from 'react';
import {PageHeader, Form, Input, Button, Upload} from "antd";
import {Link, useHistory} from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import JSZip from 'jszip';
import ReactImageAnnotate from "react-image-annotate";
import * as keypointDefinition from '../../keypointDefinition.js';
import Loading from "../common/Loading";

/**
 * 결과 업로드 및 뷰
 * @returns {JSX.Element}
 * @constructor
 */
const AdminUpload = ({}) => {

    const history = useHistory();
    const [isLoading, setLoading]  = useState(true);
    const [imageFileList, setImageFiles] = useState([]);
    const [totalDataList, setTotalData] = useState(false);
    const [totalLength, setTotalLength] = useState(0);
    const [partIndex, setPartIndex] = useState(0);

    const [form] = Form.useForm();
    const [files, setFiles] = useState([]);
    const [isUploaded, setIsUploaded] = useState(false);
    const kpdef = keypointDefinition.Definitions; 

    async function onFinish(values) {
        //압축파일 풀고 파싱하고 그대로 뷰페이지로 넘기면 된다. 와!
        if(files.length){
            let reader = new FileReader(); 
            reader.onload = async (file) => {
                JSZip.loadAsync(file.target.result).then(async function ( zip ) {
                    let zipInst = zip;
                    await parseAnnotationResult(zipInst)
                }); 
            } 
            reader.readAsArrayBuffer(files[0]);
        }
    };

    useEffect(() => {
        if(totalDataList){
            setTotalLength(totalDataList.length);
            setImageFiles(totalDataList.slice(0,900));
            setIsUploaded(true);
            setLoading(false);
        }
    },[totalDataList]);

    async function parseAnnotationResult(zip) {

        //이미지 저장
        let images = new Array();
        let imageSize = (Object.keys(zip.files).length)/2;
        let regionJson = new Object();

        for(const relativePath in zip.files){
            let dirType = relativePath.split('\\');
            if(dirType[0] == "라벨링"){
                //라벨데이터 쪽
                zip.file(relativePath).async("text").then((text) => { 
                    const obj = JSON.parse(text);
                    let name = dirType[1].split('.')[0];
                    let regi = obj['Learning data Info']['annotation']
                    for(let j=0;j<regi.length;j++){
                        Object.assign(regi[j], {"highlighted": false,"editingLabels": false,"isInspect":true,"id":Math.random().toString().split(".")[1],"color":"red"});
                    }
                    regionJson[name] = regi;
                    regionJson["meta___" + name] = obj['Learning data Info']['metaDescription'];
                })
            } else if(dirType[0] == "원천"){
                //사진 쪽
                zip.file(relativePath).async("base64").then((base64Text) => { 
                    let name = dirType[1].split('.')[0];
                    images.push({
                        src : 'data:image/jpeg;base64, '+ base64Text,
                        name : '/' + imageSize,
                        regions : regionJson[name] ? regionJson[name] : [],
                        metaDescription : regionJson["meta___" + name] ? regionJson["meta___" + name] : "  "
                    })
                    if((images.length) == imageSize){
                        //console.log(images)
                        //console.log(regionJson)
                        setTotalData(images);
                    }
                })
            } else {
                alert("압축파일의 형식이 올바르지 않습니다!");
                setFiles([]);
                return false;
            }
        }
    }

    const props = {
        onRemove: file => {
            setFiles([]);
        },
        beforeUpload: (file) => {
            setFiles([file]);
            return false;
        },
        accept : ".zip,.rar",
    };

    const formItemLayout = {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }

    const buttonItemLayout = {
            wrapperCol: {
            span: 14,
            offset: 4,
            },
        };

    if(!isUploaded) {

        return (
            <>
            <Form
            {...formItemLayout}
                name="adminUpload"
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    name="fileUpload"
                    label="결과파일"
                >
                    <Upload {...props} fileList={files}>
                        <Button icon={<UploadOutlined />}>업로드</Button>
                    </Upload>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button type="primary" htmlType="submit">보기</Button>
                </Form.Item>
            </Form>
            </>
        );
    }

    if(isLoading) {
        return (<Loading />);
    }

    const AnnotationTool = ReactImageAnnotate

    return (
        <AnnotationTool
          labelImages
          regionClsList={[]}
          workType={"admin"}
          keypointDefinitions={kpdef}
          enabledTools={["pan","zoom","나가기"]}
          images={imageFileList}
          totalData={totalDataList}
          totalLength={totalLength}
          partIndex={partIndex}
          jobInfo={{}}
          onExit={(args) =>{
            history.push('/projectInfo');
          }}
          onSave={(args,totalDataArray) => {
          }}
        />
    );

};

export default AdminUpload;
