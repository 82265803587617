import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import { Typography } from 'antd';

const { Title } = Typography;
//antd
import { Menu } from 'antd';

function SideMenu({location, projectInfo}) {
    let title = projectInfo.title.length > 12 ? projectInfo.title.substr(0,11) + "..." : projectInfo.title;
    let status = projectInfo.status;
    let participant = projectInfo.participantStatus;
    let titleHead = (
    <Title level={5} style={{ paddingLeft: '1rem', paddingTop: '1rem',  minHeight: 64, margin: 0 }}>
        {title}
    </Title>
    );


    let menuItems;

    if(participant === "작업자") {
        menuItems = 
        <>
            <Menu.Item key="/projectInfo">
                <Link to={"/projectInfo"}>대시보드</Link>
            </Menu.Item>

            {status === "종료" || status === "정지" ? "" : 
            <>
            <Menu.Item key="/jobTrgtList">
            <Link to="/jobTrgtList">새 작업 할당</Link>
            </Menu.Item>

            <Menu.Item key="/jobProgressList">
                <Link to="/jobProgressList">진행 중 작업</Link>
            </Menu.Item>
            </>
            }

            <Menu.Item key="/jobCompleteList">
                <Link to="/jobCompleteList">완료된 작업</Link>
            </Menu.Item>

            <Menu.Item key="/account">
                <Link to="/account">정산 관리</Link>
            </Menu.Item>
            
            <Menu.Item key="/qnaList">
                <Link to="/qnaList">묻고 답하기</Link>
            </Menu.Item>

            <Menu.Item key="/notice">
                <Link to="/notice">공지사항</Link>
            </Menu.Item>
        </>

    } else if(participant === "검수자") {
        menuItems = <>

            <Menu.Item key="/projectInfo">
                <Link to="/projectInfo">대시보드</Link>
            </Menu.Item>

            {status === "종료" || status === "정지" ? "" : 
            <>
            <Menu.Item key="/inspcTrgtsList">
                <Link to="/inspcTrgtsList">새 검수 할당</Link>
            </Menu.Item>

            <Menu.Item key="/inspcProgressList">
                <Link to="/inspcProgressList">진행 중 검수</Link>
            </Menu.Item>
            </>
            }
            <Menu.Item key="/inspcCompleteList">
                <Link to="/inspcCompleteList">완료된 검수</Link>
            </Menu.Item>

            <Menu.Item key="/account">
                <Link to="/account">정산 관리</Link>
            </Menu.Item>

            <Menu.Item key="/qnaList">
                <Link to="/qnaList">묻고 답하기</Link>
            </Menu.Item>

            <Menu.Item key="/notice">
                <Link to="/notice">공지사항</Link>
            </Menu.Item>

        </>
    } else if(participant === "관리자") {
        menuItems = <>

            <Menu.Item key="/projectInfo">
                <Link to="/projectInfo">대시보드</Link>
            </Menu.Item>

            <Menu.Item key="/jobAdmin">
                <Link to="/jobAdmin">작업 및 검수 목록</Link>
            </Menu.Item>

            <Menu.Item key="/jobAdminComplete">
                <Link to="/jobAdminComplete">완료된 작업 및 검수 목록</Link>
            </Menu.Item>

            <Menu.Item key="/qnaList">
                <Link to="/qnaList">묻고 답하기</Link>
            </Menu.Item>
            
            <Menu.Item key="/notice">
                <Link to="/notice">공지사항</Link>
            </Menu.Item>

            {/* <Menu.Item key="/adminUpload">
                <Link to="/adminUpload">결과파일 업로드</Link>
            </Menu.Item> */}
        </>
    }

    return (
        <Menu
            mode="inline"
            style={{ height: '100%' }}
            selectedKeys={location.pathname ? location.pathname : "/projectInfo"}
        >
            {titleHead}
            
            {menuItems}
        </Menu>
    );
}

export default SideMenu;
