import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Row, Col, Descriptions, PageHeader} from "antd";

/**
 * 프로젝트 종료 정보확인
 */
const ProjectInfo = ({ projectService }) => {
    const [ project, setProject ] = useState({});
    const { projectNo } = useParams();

    useEffect(() => {
        projectService.endProjectInfo(projectNo).then(r => {

            console.log(r);
            setProject(r);
            
        });

    },[projectService]);

    return (
        <>
            <PageHeader
                className="site-page-header"
                title={project.title}
            />
            <Descriptions bordered>
                <Descriptions.Item label="프로젝트 명칭">{project.title}</Descriptions.Item>
                <Descriptions.Item label="목표 수량" span={2}>{project.targetAmount}개</Descriptions.Item>
                <Descriptions.Item label="프로젝트 설명" span={3}>{project.content}</Descriptions.Item>
                <Descriptions.Item label="프로젝트 기간" span={3}>
                    <Row>
                        <Col span={8}>시작일 : {project.startDate}</Col>
                        <Col span={8}>종료일 : {project.endDate}</Col>
                        <Col span={8}>남은 기간 : {project.remainingDate}</Col>
                    </Row>
                </Descriptions.Item>
            </Descriptions>
            <br />
            <PageHeader
                className="site-page-header"
                title="담당자 정보"
            />
            <Descriptions bordered>
                <Descriptions.Item label="담당자 이름">{project.mngrName}</Descriptions.Item>
                <Descriptions.Item label="전화번호" span={2}>{project.mngrPhone1}</Descriptions.Item>
                <Descriptions.Item label="휴대전화번호" >{project.mngrPhone1}</Descriptions.Item>
                <Descriptions.Item label="이메일주소" >{project.mngrEmail1}@{project.mngrEmail2}</Descriptions.Item>
            </Descriptions>

        </>
    );
};

export default ProjectInfo;
