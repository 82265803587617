import React, { memo, useEffect, useState } from "react";
import Loading from "../../common/Loading";
import ReactImageAnnotate from "react-image-annotate";
import { Button, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import makeImmutable from "seamless-immutable";
import * as keypointDefinition from "../../../keypointDefinition.js";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";
import { DashboardFilled } from "@ant-design/icons";

/**
 * 어노테이션 작업 페이지
 * @returns {JSX.Element}
 * @constructor
 */
const AnnotationImage = memo(
  ({ projectInfo, annotationService, workFolderInfo }) => {
    const history = useHistory();
    const params = useParams();

    //각종 param 상수
    const projectNo = projectInfo.projectNo, //프로젝트 PK
      role = workFolderInfo.role, //작업자/검수자 구분
      annotationType = workFolderInfo.dataType, //작업유형 (OBJ,FL,OCR,...)
      workFolderNo = workFolderInfo.taskFolderNo, //작업폴더 정보 오브젝트
      jobStatus = workFolderInfo.status, //작업상태(작업/재작업/검수..)
      kpdef = keypointDefinition.Definitions, //키포인트 위치 고정상수
      tools =
        role == "WORKER"
          ? annotationType == "OCR"
            ? [
                "show-tags",
                "pan",
                "create-ocr",
                "create-blurbox",
                "copy",
                "저장",
                "나가기",
              ]
            : annotationType == "CAPTIONING"
            ? ["pan", "copy", "zoom", "저장", "나가기"]
            : [
                "show-tags",
                "pan",
                "create-ocr",
                "create-box",
                "create-polygon",
                "create-blurbox",
                "create-cuboid",
                "create-polyline",
                "create-keypoints",
                "copy",
                "저장",
                "나가기",
              ]
          : ["pan", "zoom", "저장", "나가기"],
      headApi = process.env.REACT_APP_BASE_URL + "/images/", //headAPi 주소. 최상위에 .env 파일을 작성하여 지정할 것.
      //REACT_APP_BASE_URL=http://at-web.vitasoft.co.kr/backend/v1/api
      tokenInfo = annotationService.getHeaders(); //로드 이미지 주소.
    let resetLabelFlag =
      role == "WORKER" && localStorage.getItem("resetLabelFlag")
        ? localStorage.getItem("resetLabelFlag")
        : true;
    let sourceList = new Array();
    let rejectedObj = new Object(); // 재작업 시 반려한 목록 가이드
    const [currentPage, setCurrentPage] = useState({
      idx: 0,
      image: {},
    });
    const [inputs, setInputs] = useState({
      isLoading: true,
      totalDataList: [],
      totalLength: 0,
      objectCodeList: [],
      KPObjectCodeList: [],
      tagList: [],
      metaColList: {},
      rejectCodeObj: {},
      rejectDesc: {},
    });

    const {
      isLoading,
      totalDataList,
      totalLength,
      objectCodeList,
      KPObjectCodeList,
      tagList,
      metaColList,
      rejectCodeObj,
      rejectDesc,
    } = inputs;

    //나가기 모달 파트
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    //모달 끝

    //작업자용 데이터 파싱
    async function makeResJsonJob(resObj, idx) {
      let regi = undefined;
      let jsonData = resObj.jsonData;
      let keyObj = {};
      let typeCnt = {};
      let captions = [];
      let preLabelData = [];
      let preCaptionData = [];
      let lpPreLabelData = [];

      for await (let r of jsonData) {
        if (jobStatus == "5") {
          regi = r.labelData
            ? regi
              ? regi.concat(
                  r.labelData &&
                    r.labelData.filter((r) => {
                      return r.type !== "caption";
                    })
                )
              : r.labelData &&
                r.labelData.filter((r) => {
                  return r.type !== "caption";
                })
            : regi;
          captions = r.labelData
            ? captions
              ? captions.concat(
                  r.labelData &&
                    r.labelData.filter((r) => {
                      return r.type == "caption";
                    })
                )
              : r.labelData &&
                r.labelData.filter((r) => {
                  return r.type == "caption";
                })
            : captions;
          keyObj[r.dataType] = r.jsonDataNo;
        } else {
          if (resetLabelFlag) {
            regi = r.labelData
              ? regi
                ? regi.concat(
                    r.labelData &&
                      r.labelData.filter((r) => {
                        return r.type !== "caption";
                      })
                  )
                : r.labelData &&
                  r.labelData.filter((r) => {
                    return r.type !== "caption";
                  })
              : regi;
            captions = r.labelData
              ? captions
                ? captions.concat(
                    r.labelData &&
                      r.labelData.filter((r) => {
                        return r.type == "caption";
                      })
                  )
                : r.labelData &&
                  r.labelData.filter((r) => {
                    return r.type == "caption";
                  })
              : captions;
          }
          if (Object.keys(r.preLabelData).length > 0) {
            for (const [key, value] of Object.entries(r.preLabelData)) {
              switch (key.toUpperCase()) {
                case "BBOX":
                  // preLabelData =
                  //   r.dataType == "BBOX"
                  //     ? preLabelData.concat(value)
                  //     : preLabelData;
                  preLabelData = preLabelData.concat(value);
                  break;
                case "CAPTION":
                  preCaptionData = preCaptionData.concat(value);
                  break;
                default:
                  break;
              }
            }
          }
          const {
            ["preLabelData"]: pre,
            ["preCaptionData"]: preC,
            ["labelData"]: labeldatas,
            ...keyData
          } = r;
          keyObj[keyData.dataType] = keyData.jsonDataNo;
          if (r.labelData && r.labelData.length > 0) {
            typeCnt[r.dataType] = r.labelData.length;
          }
        }
      }
      let filename = resObj.filename ? resObj.filename : null;
      let inspectCode = resObj.rejectedCode ? resObj.rejectedCode : null;
      let inspectReason = resObj.rejectedReason ? resObj.rejectedReason : null;
      let metaDescription = resObj.metaDescription
        ? resObj.metaDescription
        : {};
      let captionCnt =
        captions && captions.filter((caption) => caption.clicked).length;
      let json = {
        key: keyObj,
        src: headApi + resObj.fileNo + "?taskFolderNo=" + workFolderNo,
        name: idx + 1,
        isViewed: regi ? true : false,
        regions: regi,
        preLabelData: regi == undefined ? preLabelData : [],
        preCaptionData: captions !== undefined ? preCaptionData : [],
        lpPreLabelData: regi == undefined ? lpPreLabelData : [],
        typeCnt: typeCnt, //왜쓰는지 확인 필요
        metaDescription: metaDescription,
        inspectCode: inspectCode,
        inspectReason: inspectReason,
        captions: captions,
        captionCnt: captionCnt,
        filename: filename,
      };

      if (jobStatus == "5") {
        // if (inspectCode && inspectCode != 1) {
        if (inspectCode && inspectCode != 0) {
          let rejectIndex = Object.keys(rejectedObj).length;
          rejectedObj[rejectIndex + 1] = [inspectCode, inspectReason];
          return json;
        } else {
          return false;
        }
      }

      if (params && params.isRemain == "remain" && captionCnt === 10) {
        return false;
      } else {
        return json;
      }
    }

    //검수자용 데이터 파싱
    async function makeResJsonInspect(resObj, idx) {
      let regi = [];
      let jsonData = resObj.jsonData;
      let keyObj = {};
      let typeCnt = {};
      let captions = [];

      if (jsonData[0].labelData != null) {
        for await (let r of jsonData) {
          regi = r.labelData
            ? regi
              ? regi.concat(
                  r.labelData &&
                    r.labelData.filter((r) => {
                      return r.type !== "caption";
                    })
                )
              : r.labelData &&
                r.labelData.filter((r) => {
                  return r.type == "caption";
                })
            : regi;
          captions = r.labelData
            ? captions
              ? captions.concat(
                  r.labelData &&
                    r.labelData.filter((r) => {
                      return r.type == "caption";
                    })
                )
              : r.labelData &&
                r.labelData.filter((r) => {
                  return r.type == "caption";
                })
            : captions;
          const { ["labelData"]: labeldatas, ...keyData } = r;
          if (r.labelData && r.labelData.length > 0) {
            typeCnt[r.dataType] = r.labelData.length;
          }
          keyObj[keyData.dataType] = keyData.jsonDataNo;
          for (let j = 0; j < regi.length; j++) {
            Object.assign(regi[j], { isInspect: true });
          }
          for (let j = 0; j < captions.length; j++) {
            Object.assign(captions[j], { isInspect: true });
          }
        }
      }

      let filename = resObj.filename ? resObj.filename : null;
      // let inspectCode = resObj.status ? resObj.rejectedCode : 1;
      let inspectCode = resObj.status ? resObj.rejectedCode : 0;
      let inspectReason = resObj.rejectedReason ? resObj.rejectedReason : "";
      let metaDescription = resObj.metaDescription
        ? resObj.metaDescription
        : {};
      let json = {
        key: keyObj,
        src: headApi + resObj.fileNo + "?taskFolderNo=" + workFolderNo,
        name: idx + 1,
        isViewed: resObj.status ? true : false,
        regions: regi,
        captions: captions,
        preLabelData: [],
        preCaptionData: [],
        lpPreLabelData: [],
        metaDescription: metaDescription,
        inspectCode: inspectCode,
        inspectReason: inspectReason,
        filename: filename,
      };
      // if (inspectCode && inspectCode != 1) {
      if (inspectCode && inspectCode != 0) {
        rejectedObj[idx + 1] = [inspectCode, inspectReason];
      }

      if (params && params.isRemain == "remain" && resObj.status != "0") {
        return false;
      } else {
        return json;
      }
    }

    //태그 / 반려사유 / 메타정보 / 라벨정보 설정
    async function setClassCodes(classCodes) {
      let tags = new Array(),
        rejects = new Object(),
        codes = new Array(),
        kpcodes = new Array(),
        metaz = new Object();

      for (let codeObj of classCodes) {
        switch (codeObj.dataType) {
          case "OBJ":
            codes.push(codeObj.className);
            break;
          case "REJECT":
            rejects[codeObj.classNo] = codeObj.classDescription;
            break;
          case "TAG":
            tags.push(codeObj.className);
            break;
          case "SKE17":
            annotationType != "SKE24" && kpcodes.push(codeObj.className);
            break;
          case "SKE24":
            annotationType == "SKE24" && kpcodes.push(codeObj.className);
            break;
          case "META":
            metaz[codeObj.className] = codeObj.classDescription.split("|");
            break;
          default:
            break;
        }
      }
      codes = codes.concat(["얼굴", "번호판"]);
      const set = new Set(codes);

      const classObj = {
        tagList: tags,
        objectCodeList: [...set],
        KPObjectCodeList: [...kpcodes, "키포인트"],
        rejectCodeObj: jobStatus != "1" ? rejects : {},
        metaColList: metaz,
      };

      return classObj;
    }

    //init
    useEffect(() => {
      annotationService
        .getFolderImages(workFolderNo, annotationType)
        .then(async function(res) {
          //console.log(workFolderInfo)
          console.log(res);
          const images = res.images;
          const classCodes = res.classCodes;
          const classObj = await setClassCodes(classCodes);

          if (role == "WORKER") {
            for await (let [idx, resObj] of images.entries()) {
              const json = await makeResJsonJob(resObj, idx);
              json && sourceList.push(json);
            }
          } else if (role == "INSPECTOR") {
            for await (let [idx, resObj] of images.entries()) {
              const json = await makeResJsonInspect(resObj, idx);
              json && sourceList.push(json);
            }
          }
          //결과 없으면 일단 내보내자.
          if (sourceList.length == 0) {
            alert("이미지 로드 중 오류가 발생했습니다.");
            history.go(-1);
            return false;
          }

          //재개 시 저장 페이지부터 시작
          let page = 0;
          if (!(params && params.isRemain == "remain")) {
            page =
              window.localStorage.getItem(workFolderNo) && jobStatus != "5"
                ? parseInt(
                    Number(
                      window.localStorage
                        .getItem(workFolderNo)
                        .replace(/[^0-9\.]+/g, "")
                    )
                  )
                : 0;
          }

          if (
            (jobStatus == "0" && !(params && params.isRemain == "remain")) ||
            (jobStatus == "1" && !(params && params.isRemain == "remain")) ||
            (jobStatus == "2" && !(params && params.isRemain == "remain")) ||
            (jobStatus == "3" && !(params && params.isRemain == "remain")) ||
            (jobStatus == "6" && !(params && params.isRemain == "remain"))
          )
            page =
              // window.localStorage.getItem(workFolderNo)
              //   ? parseInt(
              //       Number(
              //         window.localStorage
              //           .getItem(workFolderNo)
              //           .replace(/[^0-9\.]+/g, "")
              //       )
              //     )
              //   :
              0;

          localStorage.setItem("resetLabelFlag", true);
          let loadImage = makeImmutable.asMutable(sourceList[page]);
          if (!loadImage.blobUrl) {
            await axios
              .get(loadImage.src, {
                headers: tokenInfo,
                responseType: "arraybuffer",
              })
              .then((res) => {
                let blob = new Blob([res.data], { type: "image/png" });
                const url = window.URL.createObjectURL(blob);
                loadImage.blobUrl = url;
              })
              .catch((error) => {
                console.log(error.response.status);
              });
          }

          setCurrentPage({
            idx: page,
            image: [loadImage],
          });
          setInputs({
            ...inputs,
            ...classObj,
            totalDataList: sourceList,
            totalLength: sourceList.length,
            rejectDesc: rejectedObj,
            isLoading: false,
          });
        })
        .catch((error) => {
          if (error.message === "Cannot convert undefined or null to object")
            notification["error"]({
              message:
                "작업자 툴 (Front) 이미지 로드 실패 관리자에게 문의하세요",
            });
          if (error.response)
            notification["error"]({
              message: "API 호출 및 이미지 로드 실패 관리자에게 문의하세요",
            });
          console.error(error);
        });
    }, [annotationService]);

    const AnnotationTool = ReactImageAnnotate;
    return (
      <>
        {isLoading ? (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Skeleton
                variant="text"
                width={300}
                height={50}
                style={{ marginLeft: "20px" }}
              />
              <Skeleton
                variant="rect"
                width={160}
                height={38}
                style={{ marginRight: "140px", marginTop: "5px" }}
              />
              <Skeleton
                variant="rect"
                width={200}
                height={38}
                style={{ marginTop: "5px" }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <Skeleton
                variant="rect"
                width={20}
                height={650}
                style={{ marginLeft: "15px" }}
              />
              <Skeleton
                variant="rect"
                width={1350}
                height={780}
                style={{ marginLeft: "15px" }}
              />
              <Skeleton
                variant="rect"
                width={400}
                height={780}
                style={{ marginLeft: "5px" }}
              />
            </div>
          </div>
        ) : (
          <>
            <AnnotationTool
              workType={role == "INSPECTOR" ? "inspect" : "job"}
              labelImages
              enabledTools={tools}
              regionClsList={objectCodeList}
              kpClsList={KPObjectCodeList}
              regionTagList={tagList}
              keypointDefinitions={kpdef}
              metaDataColumn={metaColList}
              rejectedDescription={rejectDesc}
              rejectCodeObj={rejectCodeObj}
              images={currentPage.image}
              totalLength={totalLength}
              selectedImage={0}
              jobInfo={workFolderInfo}
              tokenInfo={tokenInfo}
              currentIdx={currentPage.idx}
              onPageChange={async (prevPageData, prevPageIdx, nextPageIdx) => {
                //console.log(prevPageData)
                let tempData = makeImmutable.asMutable(totalDataList);
                let nextData = makeImmutable.asMutable(tempData[nextPageIdx]);
                if (!nextData.blobUrl) {
                  await axios
                    .get(nextData.src, {
                      headers: tokenInfo,
                      responseType: "arraybuffer",
                    })
                    .then((res) => {
                      let blob = new Blob([res.data], { type: "image/png" });
                      const url = window.URL.createObjectURL(blob);
                      nextData.blobUrl = url;
                    });
                }
                setCurrentPage({
                  idx: nextPageIdx,
                  image: [nextData],
                });
                tempData.splice(prevPageIdx, 1, prevPageData);
                setInputs({
                  ...inputs,
                  totalDataList: tempData,
                });
              }}
              onExit={(args) => {
                let savedPage = window.localStorage.getItem(workFolderNo)
                  ? parseInt(
                      Number(
                        window.localStorage
                          .getItem(workFolderNo)
                          .replace(/[^0-9\.]+/g, "")
                      )
                    ) + 1
                  : 1;
                let curPage = parseInt(currentPage.idx + 1);
                let curCheck =
                  JSON.stringify(args.images[0].regions) ==
                  JSON.stringify(totalDataList[currentPage.idx].regions);

                if (savedPage == curPage && curCheck) {
                  history.go(-1);
                } else {
                  handleClickOpen();
                }
              }}
              onSave={(args) => {
                //저장(제출) 버튼 클릭 시 json 데이터 제출
                console.log(args);

                //분리한 데이터 재통합
                let tempData = makeImmutable.asMutable(totalDataList);
                tempData.splice(currentPage.idx, 1, args.images[0]);

                //전송 리스트 선언
                let jsonList = new Array();
                let reqList = new Array();

                //전송 데이터 파싱 (FL 과 FL 아닌 경우로 구분)
                if (annotationType == "FL") {
                  let lpJsonList = new Array();
                  for (let data of tempData) {
                    if (!data.isViewed) continue;
                    let labelData =
                      data.regions == undefined ? [] : data.regions;
                    let faceLabelData = [];
                    let lpLabelData = [];
                    labelData.forEach((element, idx) => {
                      element.cls === "번호판"
                        ? lpLabelData.push(element)
                        : faceLabelData.push(element);
                    });
                    var json = {
                      jsonDataNo: data.key["FACE"],
                      labelData: faceLabelData,
                      metaDescription: data.metaDescription
                        ? data.metaDescription
                        : {},
                      labelCnt: faceLabelData.length,
                    };
                    var lpJson = {
                      jsonDataNo: data.key["LP"],
                      labelData: lpLabelData,
                      metaDescription: data.metaDescription
                        ? data.metaDescription
                        : {},
                      labelCnt: lpLabelData.length,
                    };
                    //검수 시 한정
                    if (role == "INSPECTOR") {
                      let json2 = Object.assign(json, {
                        rejectedCode: data.inspectCode,
                        rejectedReason: data.inspectReason,
                        // status: data.inspectCode == 1 ? "1" : "3",
                        status: data.inspectCode == 0 ? "1" : "3",
                      });
                      let lpJson2 = Object.assign(lpJson, {
                        rejectedCode: data.inspectCode,
                        rejectedReason: data.inspectReason,
                        // status: data.inspectCode == 1 ? "1" : "3",
                        status: data.inspectCode == 0 ? "1" : "3",
                      });
                      jsonList.push(json2);
                      lpJsonList.push(lpJson2);
                    } else {
                      jsonList.push(json);
                      lpJsonList.push(lpJson);
                    }
                  }
                  reqList.push(lpJsonList);
                } else {
                  for (let data of tempData) {
                    //미열람 데이터는 저장 안함
                    if (!data.isViewed) continue;
                    let labelData =
                      data.regions == undefined ? [] : data.regions;
                    let captionData =
                      data.captions == undefined ? [] : data.captions;
                    let json = {
                      jsonDataNo: data.key[annotationType],
                      labelData: labelData.concat(captionData),
                      metaDescription: data.metaDescription
                        ? data.metaDescription
                        : {},
                      labelCnt: labelData.length,
                      captionCnt:
                        captionData &&
                        captionData.filter((caption) => caption.clicked).length,
                    };

                    //검수 시 한정
                    if (role == "INSPECTOR") {
                      let json2 = Object.assign(json, {
                        rejectedCode: data.inspectCode,
                        rejectedReason: data.inspectReason,
                        status:
                          // data.inspectCode == 1
                          data.inspectCode == 0
                            ? "1"
                            : // : data.inspectCode == 1
                              // ? "0"
                              "3",
                      });
                      jsonList.push(json2);
                    } else {
                      jsonList.push(json);
                    }
                  }
                }
                reqList.push(jsonList);
                // console.log("====보내는값:",workFolderNo)
                // console.log(jsonList);
                // console.log(reqList);

                //미열람 작업분이 아닌 경우 현재 페이지 저장
                if (!(params && params.isRemain == "remain")) {
                  let curPage = document.getElementById("pagingBox").value;
                  if (curPage) {
                    window.localStorage.setItem(workFolderNo, curPage - 1);
                  }
                }

                //작업 전송
                if (role == "WORKER") {
                  annotationService
                    .postJobJson(reqList, workFolderNo)
                    .then(function(res) {
                      notification["success"]({
                        message: "작업 정보 저장 완료",
                      });
                    })
                    .catch((error) => {
                      console.error(error);
                      notification["error"]({
                        message: "저장에 실패하였습니다.",
                      });
                    });
                } else if (role == "INSPECTOR") {
                  annotationService
                    .postInspectJson(reqList, workFolderNo)
                    .then(function(res) {
                      notification["success"]({
                        message: "검수 정보 저장 완료",
                      });
                    })
                    .catch((error) => {
                      console.error(error);
                      notification["error"]({
                        message: "저장에 실패하였습니다.",
                      });
                    });
                }
              }}
            />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`알림`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  변경사항이 저장되지 않을 수 있습니다. 작업 페이지를
                  나가시겠습니까?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  취소
                </Button>
                <Button
                  onClick={() => history.go(-1)}
                  color="primary"
                  autoFocus
                >
                  나가기
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    );
  }
);

export default AnnotationImage;
