import React, {useEffect, useState} from 'react';
import {Button, Descriptions, PageHeader} from "antd";
import {useParams,useHistory} from "react-router-dom";
import ContentEditable from 'react-contenteditable'
import Loading from "../../common/Loading";
import { DownloadOutlined } from '@ant-design/icons';

const NoticeDetail = ({articleService, projectInfo}) => {

    const [isLoading, setLoading] = useState(true);
    const [noticeView, setNoticeView] = useState([]);
    const [contentEditable] = useState(React.createRef());

    let projectNo = projectInfo.projectNo;
    const { articleId } = useParams();
    const history = useHistory();

    useEffect(() => {
        articleService.getNoticeView(articleId,projectNo).then(r => {
            setNoticeView(r);
            setLoading(false);
        })

    },[articleService]);

    const onBtnList = () => {
        history.push('/notice');
    }

    const downloadFile = (file,displayName) => {
        let blob = new Blob([file], {
          type: "application/octet-stream",
        });
        let name = displayName; //파일명 디코딩
        let fileName = getFileName(name);
        if (window.navigator.msSaveOrOpenBlob) { // IE 10+는 해당 함수 제공
          window.navigator.msSaveOrOpenBlob(blob, name);
        } else {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.target = '_self';
          if (name) link.download = name;
          link.click();
          window.URL.revokeObjectURL(link); //메모리 누수 방지
        }
    };

    const getFileName = (contentDisposition) => {
        let fileName = contentDisposition
        .split(';')
        .filter((el) => el.indexOf('filename') > -1)
        .map((ele) => ele.replace(/"/g, '').split('=')[1]);
        return fileName[0];
    
    };

    const onFileView = (articleFileId,displayName) => {
        articleService.getNoticeFileView(projectNo,articleId,articleFileId).then(r => {
            downloadFile(r,displayName);
        })
    }

    if(isLoading) {
        return (<Loading />);
    }

    return (
        <>

            <style jsx="true">{`
                .ant-descriptions-item-label {
                    width:160px!important
                }
            `}</style>

            <PageHeader
                className="site-page-header"
                title="공지사항 상세"
                extra={[<Button onClick={() =>onBtnList()}>목록</Button>]}
            />

            <Descriptions bordered>
                <Descriptions.Item label="제목" span={3}>{noticeView.title}</Descriptions.Item>
                <Descriptions.Item label="작성일" span={3}>{noticeView.regDate.replace("T"," ")}</Descriptions.Item>
                <Descriptions.Item id="content" label="내용" span={3}>
                    <ContentEditable tagName='article' innerRef={contentEditable} html={noticeView.content} disabled={true} />
                </Descriptions.Item>
                <Descriptions.Item id="files" label="첨부파일" span={3}>
                    {noticeView.files ? noticeView.files.map((f) =>
                    (
                    <Button type="link" icon={<DownloadOutlined />} onClick={() => onFileView(f.articleFileId,f.displayName)}>{f.displayName}</Button>
                    )
                    )
                    : ""}
                </Descriptions.Item>
            </Descriptions>
        </>
    );
};

export default NoticeDetail;

